import { Book } from "../types/material";
import * as skTypes from "../types/sk";
import * as utils from "./skUtils";

export const hasSub = (
  primaryMoment: skTypes.Moment,
  previousMoment?: skTypes.Moment
): boolean => {
  let afterPause = false;

  // seats array
  if (primaryMoment.onSeats && previousMoment?.onSeats) {
    if (primaryMoment.onSeats.length !== previousMoment.onSeats?.length)
      afterPause = true;
    if (
      primaryMoment.onSeats.some(
        (seat, seatIndex) => previousMoment.onSeats?.[seatIndex] !== seat
      )
    )
      afterPause = true;
  }

  // bench array
  if (primaryMoment.onBench && previousMoment?.onBench) {
    if (primaryMoment.onBench.length !== previousMoment.onBench?.length)
      afterPause = true;
    if (
      primaryMoment.onBench.some(
        (seat, seatIndex) => previousMoment.onBench?.[seatIndex] !== seat
      )
    )
      afterPause = true;
  }

  return afterPause;
};

export const getWordPercentage = (
  jumps: Partial<Record<skTypes.JumpType, number>>
): number => {
  let word: number = 0;
  let noWord: number = 0;

  (Object.keys(jumps) as skTypes.JumpType[]).forEach(
    (key: skTypes.JumpType) => {
      const amount: number = jumps[key as skTypes.JumpType] || 0;
      if ([skTypes.multipleWords, skTypes.oneWord, skTypes.read].includes(key))
        word += amount;
      else noWord += amount;
    }
  );
  return Math.round((word * 100.0) / (noWord + word));
};

export const getJumpType = (moment: skTypes.Moment): skTypes.JumpType | "" => {
  // Using the error type (if this moment was an error), and the reference and question provided,
  // Figure out what type of jump this was
  if (!utils.isQuestion(moment) || moment.book === undefined) return "";
  let jumpType: skTypes.JumpType | "" = "";
  if (moment.errorType === skTypes.split) return skTypes.splitRef;
  if (moment.errorType === skTypes.ref) return skTypes.reference;
  if (moment.chapter !== undefined && moment.chapter !== -1) {
    // Use words given to figure it out
    if (moment.verse === undefined || moment.verse === -1) {
      jumpType = skTypes.splitRef;
    } else {
      const { words, read } = utils.getWordsReceived(moment);
      if (words === 1) return skTypes.oneWord;
      if (words > 1) return skTypes.multipleWords;
      if (words < 0) return skTypes.splitRef;
      if (read) return skTypes.read;
      return skTypes.reference;
    }
  }
  return jumpType;
};

/**
 * calculates the odds, returns them, and caches the results
 * @param luckCaching current known data for expected from jump
 * @returns the odds of getting the question right, under these conditions
 */
export const calculateOdds = (
  material: Book[],
  book: number,
  chapter: number,
  verse: number,
  words: number,
  read: boolean,
  receivedCharacters: string,
  luckCaching: Record<string, number>
): { jump: number; question: number } => {
  try {
    // Calculate answer/question luck
    let possible = 0;

    try {
      material[book].chapters[chapter][verse].questions.forEach((q) => {
        if (q.startsWith(receivedCharacters)) possible++;
      });
    } catch {
      possible = 1;
    }
    let expectedFromQuestion = 1.0 / Math.max(possible, 1);
    // Quotes on reference jumps
    if (!receivedCharacters && possible >= 1)
      expectedFromQuestion = expectedFromQuestion * 0.85;

    // Calculate jump luck

    // Get cached data
    const key = `${book}-${chapter}-${words}-${read}`;
    if (luckCaching[key]) return { jump: luckCaching[key], question: possible };

    // No cached data; calculate odds
    let total = 0;
    let expected = 0;
    material[book].chapters[chapter].forEach((v) => {
      const receivedSet = new Set<string>();
      v.questions.forEach((q) => {
        let received = "";
        let remainingWords = words;
        let char = 0;
        while (remainingWords > 0 && q.length > char) {
          if (q.charAt(char) === " ") remainingWords -= 1;
          received = received.concat(q.charAt(char));
          char++;
        }
        if (read) {
          const nextChar = q.charAt(char);
          received = received.concat(
            q.substring(char, char + (nextChar === "w" ? 2 : 1))
          );
        }
        receivedSet.add(received);
      });
      expected += receivedSet.size;
      total += v.questions.length;
    });

    let result = total === 0 ? 0 : (expected * 1.0) / total;
    // Assume quotes are always missed on references and always gotten on words/reads
    result = result * 0.85;
    if (words > 0 || read) {
      result = result + 0.15;
    } else {
      // look for quote-only verses
      let quoteOnly = 0;
      material[book].chapters[chapter].forEach((v) => {
        if (v.questions.length === 0) quoteOnly += 1;
      });
      // Figure out how often you will always guess quote
      result += (quoteOnly * 0.15) / material[book].chapters[chapter].length;
    }

    return { jump: result, question: expectedFromQuestion };
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
    return { jump: 0, question: 0 };
  }
};
export const getExpectedCorrect = (
  moment: skTypes.Moment,
  material: Book[],
  luckCaching: Record<string, number>
): { jump: number; question: number } => {
  // Make sure this is a question with information
  if (!utils.isQuestion(moment)) return { jump: 0, question: 0 };
  if (
    moment.book === undefined ||
    moment.chapter === undefined ||
    moment.verse === undefined
  )
    return { jump: 0, question: 0 };

  const { words, read } = utils.getWordsReceived(moment);

  // split reference - just say 5%
  if (words < 0 && !read) return { jump: 0.05, question: 0.05 };

  return calculateOdds(
    material,
    moment.book,
    moment.chapter,
    moment.verse,
    words,
    read,
    moment.question?.substring(0, moment.receivedCharacters) || "",
    luckCaching
  );
};

// Include overtime is a parameter here since it is also in regular Settings.
export const getEmptyStatsSettings = (
  includeOvertime: boolean
): skTypes.StatsSettings => {
  return {
    quizIds: new Set<string>(),
    quizzerIds: new Set<string>(),
    teamIds: new Set<string>(),
    chapters: new Set<string>(),
    minQuizzers: 0,
    minTeams: 0,
    quizmasters: new Set<string>(),
    includeOvertime,
    quoteOnly: false,
    afterPause: false,
    jumpTypes: new Set<skTypes.JumpType>(),
  };
};
export const questionFiltersOn = (settings: skTypes.StatsSettings): boolean => {
  return (
    settings.quizzerIds.size > 0 ||
    settings.chapters.size > 0 ||
    settings.quoteOnly ||
    settings.afterPause ||
    settings.jumpTypes.size > 0
  );
};

export const getEmptyStatsLuck = (): skTypes.StatsLuck => ({
  expectedFromJumps: 0,
  expectedFromQuestions: 0,
  actual: 0,
  choicesCorrect: 0,
});
export const getEmptyQuizzerStats = (
  quizzer: skTypes.Quizzer,
  team: skTypes.Team
): skTypes.QuizzerStats => {
  return {
    quizzerName: quizzer.quizzerName,
    quizzerId: quizzer.quizzerId,
    teamId: team.teamId,
    teamName: team.teamName,
    quizzes: new Set<string>(),
    points: 0,
    correct: 0,
    errors: 0,
    luck: getEmptyStatsLuck(),
    questionsByType: { TWENTY_POINTS: 0 },
    knowledgeErrorsByType: {},
    jumpsByType: {},
    questionsOnSeat: 0,
    seatTimeEligible: 0,
    questions: [],
    otherMoments: [],
    byQuiz: {},
  };
};

const loopMoments = (
  moments: skTypes.Moment[],
  func: (
    moment: skTypes.Moment,
    momentIndex: number,
    questionIndex: number
  ) => void
) => {
  let qIndex = 0;
  moments.forEach((moment, momentIndex) => {
    if (utils.isQuestion(moment)) qIndex += 1;
    func(moment, momentIndex, qIndex);
  });
};

export const getQuizzerStats = (
  material: Book[],
  season: skTypes.Season,
  settings: skTypes.StatsSettings
): { stats: skTypes.QuizzerStats[]; books: number[] } => {
  if (!season) {
    return { stats: [], books: [] };
  } else {
    // We have the season; calculate stats
    const books: Set<number> = new Set<number>();
    const includeOvertime = settings.includeOvertime;
    const luckCaching: Record<string, number> = {};
    const statsResult: Record<string, skTypes.QuizzerStats> = {};
    season.events.forEach((event: skTypes.Event) => {
      event.quizzes.forEach((quizSummary: skTypes.QuizSummary) => {
        const quiz: skTypes.Quiz | undefined = utils.getLocalQuiz(
          quizSummary.quizId
        );
        // Check for quiz not found or not in settings
        if (
          !quiz ||
          (settings.quizIds.size > 0 && !settings.quizIds.has(quiz.quizId)) ||
          (settings.quizmasters.size > 0 &&
            (!quiz.quizmaster || !settings.quizmasters.has(quiz.quizmaster)))
        )
          return;

        // Check for team criteria
        let foundTeamCount: number = 0;
        quiz.teams.forEach((team: skTypes.Team) => {
          if (settings.teamIds.has(team.teamId)) foundTeamCount += 1;
        });
        if (foundTeamCount < settings.minTeams) return;

        // Initialize quizzers in statsResult
        let teamStr = "";
        quiz.teams.forEach((team) => {
          teamStr = `${teamStr}${teamStr === "" ? "" : ", "}${team.teamName}`;
        });
        quiz.teams.forEach((team: skTypes.Team) => {
          team.quizzers.forEach((quizzer: skTypes.Quizzer) => {
            const qId: string = quizzer.quizzerId;
            if (!statsResult[qId])
              statsResult[qId] = getEmptyQuizzerStats(quizzer, team);
            statsResult[qId].byQuiz[quiz.quizId] = {
              quizId: quiz.quizId,
              teams: teamStr,
              quizName: quiz.quizName,
              eventId: event.eventId,
              eventName: event.eventName,
              round: quiz.round,
              site: quiz.site,
              correct: 0,
              errors: 0,
            };
          });
        });

        // Go through the quiz, putting together the box score and getting per-moment data
        const boxScore: skTypes.BoxScore = utils.getDefaultBoxScore(
          quiz.teams,
          quiz.isPractice
        );
        let atLeastOneMoment: boolean = false;
        let afterPause: boolean = true;
        loopMoments(quiz.moments, (moment, momentIndex, qIndex) => {
          if (moment.book !== undefined && moment.book > 0 && moment.book < 27)
            books.add(moment.book);
          // Possible skipping because of pause
          if (settings.afterPause) {
            const skip = !afterPause;
            afterPause = ![skTypes.tp, skTypes.error, skTypes.foul].includes(
              moment.type
            );
            if (hasSub(moment, quiz.moments[momentIndex + 1]))
              afterPause = true;
            if (skip) return;
          }
          if (
            settings.quoteOnly &&
            (!moment.question || !moment.question.includes("Quote"))
          )
            return;

          const qId: string | undefined = moment.quizzerId;

          // Check for quizzers on chairs
          let foundQuizzers: number = 0;
          if (moment.onBench) {
            moment.onBench.forEach((quizzerOnBench: string) => {
              if (!settings.quizzerIds.has(quizzerOnBench)) foundQuizzers += 1;
            });
          } else if (moment.onSeats) {
            moment.onSeats.forEach((quizzerOnSeat: string) => {
              if (settings.quizzerIds.has(quizzerOnSeat)) foundQuizzers += 1;
            });
          }
          if (foundQuizzers < settings.minQuizzers) return;

          if (settings.chapters.size > 0) {
            if (moment.book !== undefined && moment.chapter !== undefined) {
              if (!settings.chapters.has(`${moment.book}-${moment.chapter}`))
                return;
            } else {
              return;
            }
          }

          // Make sure we aren't skipping overtime, or overtime hasn't started yet
          if (includeOvertime || boxScore.quizStatus !== skTypes.overtime) {
            utils.doMoment(boxScore, moment);
            atLeastOneMoment = true;
            if (qId !== undefined && moment.team !== undefined) {
              if (utils.isQuestion(moment)) {
                const jumpType: skTypes.JumpType | "" = getJumpType(moment);
                if (
                  settings.jumpTypes.size > 0 &&
                  (!jumpType || !settings.jumpTypes.has(jumpType))
                )
                  return;

                // Seat time
                if (moment.onBench) {
                  const bench = new Set(moment.onBench);
                  boxScore.teams.forEach((team) => {
                    team.lineup.forEach((quizzerId) => {
                      const quizzer = team.quizzers[quizzerId];
                      if (
                        (quizzer.eligable && !bench.has(quizzerId)) ||
                        moment.quizzerId === quizzerId
                      )
                        statsResult[quizzerId].questionsOnSeat += 1;
                    });
                  });
                } else if (moment.onSeats) {
                  moment.onSeats.forEach((quizzerOnSeat: string) => {
                    statsResult[quizzerOnSeat].questionsOnSeat += 1;
                  });
                }

                // Luck
                if (
                  (!moment.errorType ||
                    ![skTypes.knowledge, skTypes.light].includes(
                      moment.errorType
                    )) &&
                  ((moment.book !== undefined &&
                    moment.chapter !== undefined) ||
                    moment.errorType === skTypes.ref)
                ) {
                  if (moment.type === skTypes.tp) {
                    statsResult[qId].luck.actual += 1;
                  }
                  const luckResult = getExpectedCorrect(
                    moment,
                    material,
                    luckCaching
                  );
                  statsResult[qId].luck.expectedFromJumps += luckResult.jump;
                  if (luckResult.question < 1 && luckResult.question > 0) {
                    statsResult[qId].luck.expectedFromQuestions +=
                      luckResult.question;
                    if (moment.type === skTypes.tp)
                      statsResult[qId].luck.choicesCorrect += 1;
                  }
                }

                // Points and such
                boxScore.teams.forEach((team) => {
                  team.lineup.forEach((quizzerId) => {
                    const quizzer = team.quizzers[quizzerId];
                    if (
                      (team.eligable && quizzer.eligable) ||
                      moment.quizzerId === quizzerId
                    )
                      statsResult[quizzerId].seatTimeEligible += 1;
                  });
                });
                statsResult[qId].questions.push({
                  ...moment,
                  eventName: event.eventName,
                  eventId: event.eventId,
                  round: quiz.round,
                  site: quiz.site,
                  quizName: quiz.quizName,
                  quizId: quiz.quizId,
                  qIndex,
                });

                // Question by type
                const questionType = moment.errorType
                  ? moment.errorType
                  : moment.type;
                statsResult[qId].questionsByType[questionType] =
                  (statsResult[qId].questionsByType[questionType] || 0) + 1;

                // Jump by type
                if (jumpType) {
                  statsResult[qId].jumpsByType[jumpType] =
                    (statsResult[qId].jumpsByType[jumpType] || 0) + 1;
                }

                // K-Error by type
                const kErrorType = moment.kErrorType;
                if (kErrorType) {
                  statsResult[qId].knowledgeErrorsByType[kErrorType] =
                    (statsResult[qId].knowledgeErrorsByType[kErrorType] || 0) +
                    1;
                }

                // Correct and errors by quiz
                switch (moment.type) {
                  case skTypes.tp:
                    statsResult[qId].correct++;
                    statsResult[qId].byQuiz[quiz.quizId].correct++;
                    break;
                  case skTypes.error:
                    statsResult[qId].errors++;
                    statsResult[qId].byQuiz[quiz.quizId].errors++;
                    break;
                }
              } else {
                statsResult[qId].otherMoments.push({
                  ...moment,
                  eventName: event.eventName,
                  eventId: event.eventId,
                  round: quiz.round,
                  site: quiz.site,
                  quizName: quiz.quizName,
                  quizId: quiz.quizId,
                  qIndex,
                });
              }
            }
          }
        });
        if (atLeastOneMoment) {
          boxScore.teams.forEach((team) => {
            team.lineup.forEach((quizzerId) => {
              const quizzer = team.quizzers[quizzerId];
              const qId: string = quizzerId;
              statsResult[qId].quizzes.add(quiz.quizId);
              statsResult[qId].points += quizzer.points;
            });
          });
        }
      });
    });
    return { stats: Object.values(statsResult), books: Array.from(books) };
  }
};
export const getTotalQuizzerStats = (
  stats: skTypes.QuizzerStats[]
): skTypes.QuizzerStats => {
  const result: skTypes.QuizzerStats = getEmptyQuizzerStats(
    { quizzerId: "total", quizzerName: "Total Stats" } as skTypes.Quizzer,
    { teamId: "total-team", teamName: "" } as skTypes.Team
  );

  stats.forEach((stat: skTypes.QuizzerStats) => {
    result.points += stat.points;
    result.errors += stat.errors;
    result.correct += stat.correct;
    (Object.keys(stat.questionsByType) as skTypes.QuestionType[]).forEach(
      (key: skTypes.QuestionType) => {
        result.questionsByType[key] =
          (result.questionsByType[key] || 0) + (stat.questionsByType[key] || 0);
      }
    );
    (Object.keys(stat.jumpsByType) as skTypes.JumpType[]).forEach(
      (key: skTypes.JumpType) => {
        result.jumpsByType[key] =
          (result.jumpsByType[key] || 0) + (stat.jumpsByType[key] || 0);
      }
    );
    (Object.keys(stat.knowledgeErrorsByType) as skTypes.KErrorType[]).forEach(
      (key: skTypes.KErrorType) => {
        result.knowledgeErrorsByType[key] =
          (result.knowledgeErrorsByType[key] || 0) +
          (stat.knowledgeErrorsByType[key] || 0);
      }
    );

    result.luck.actual += stat.luck.actual;
    result.luck.choicesCorrect += stat.luck.choicesCorrect;
    result.luck.expectedFromJumps += stat.luck.expectedFromJumps;
    result.luck.expectedFromQuestions += stat.luck.expectedFromQuestions;
  });

  return result;
};

export const getEmptyTeamStats = (team: skTypes.Team): skTypes.TeamStats => {
  return {
    teamId: team.teamId,
    teamName: team.teamName,
    quizzes: new Set<string>(),
    points: 0,
    correct: 0,
    luck: getEmptyStatsLuck(),
    errors: 0,
    questionsByType: { TWENTY_POINTS: 0 },
    knowledgeErrorsByType: {},
    jumpsByType: {},
    questions: [],
    otherMoments: [],
    record: [0, 0, 0],
    pointsLostToErrors: 0,
  };
};
export const getTeamStats = (
  seasonId: string,
  settings: skTypes.StatsSettings
): { stats: skTypes.TeamStats[]; books: number[] } => {
  const includeOvertime = settings.includeOvertime;
  const season: skTypes.Season | undefined = utils.getLocalSeason(seasonId);
  const books: Set<number> = new Set<number>();
  if (!season) {
    return { stats: [], books: [] };
  } else {
    // We have the season; calculate stats
    const statsResult: Record<string, skTypes.TeamStats> = {};
    season.events.forEach((event: skTypes.Event) => {
      event.quizzes.forEach((quizSummary: skTypes.QuizSummary) => {
        const quiz: skTypes.Quiz | undefined = utils.getLocalQuiz(
          quizSummary.quizId
        );
        if (
          !quiz ||
          (settings.quizIds.size > 0 && !settings.quizIds.has(quiz.quizId)) ||
          (settings.quizmasters.size > 0 &&
            (!quiz.quizmaster || !settings.quizmasters.has(quiz.quizmaster)))
        )
          return;

        // Check for team criteria
        let foundTeamCount: number = 0;
        quiz.teams.forEach((team: skTypes.Team) => {
          if (settings.teamIds.has(team.teamId)) foundTeamCount += 1;
        });
        if (foundTeamCount < settings.minTeams) return;

        // Initialize quizzers in statsResult
        quiz.teams.forEach((team: skTypes.Team) => {
          const tId: string = team.teamId;
          if (!statsResult[tId]) statsResult[tId] = getEmptyTeamStats(team);
        });

        // Go through the quiz, putting together the box score and getting per-moment data
        const boxScore: skTypes.BoxScore = utils.getDefaultBoxScore(quiz.teams);
        let overtimeRemoved: boolean = false;
        let atLeastOneMoment: boolean = false;
        let afterPause: boolean = true;
        loopMoments(quiz.moments, (moment, momentIndex, qIndex) => {
          // Possible skipping because of pause
          if (settings.afterPause) {
            const skip = !afterPause;
            afterPause = ![skTypes.tp, skTypes.error, skTypes.foul].includes(
              moment.type
            );
            if (hasSub(moment, quiz.moments[momentIndex + 1]))
              afterPause = true;
            if (skip) return;
          }
          if (
            settings.quoteOnly &&
            (!moment.question || !moment.question.includes("Quote"))
          )
            return;

          // Check for quizzers on chairs
          let foundQuizzers: number = 0;
          if (moment.onBench) {
            moment.onBench.forEach((quizzerOnBench: string) => {
              if (!settings.quizzerIds.has(quizzerOnBench)) foundQuizzers += 1;
            });
          } else if (moment.onSeats) {
            moment.onSeats.forEach((quizzerOnSeat: string) => {
              if (settings.quizzerIds.has(quizzerOnSeat)) foundQuizzers += 1;
            });
          }
          if (foundQuizzers < settings.minQuizzers) return;

          if (moment.book !== undefined && moment.book > 0 && moment.book < 27)
            books.add(moment.book);
          if (settings.chapters.size > 0) {
            if (moment.book !== undefined && moment.chapter !== undefined) {
              if (!settings.chapters.has(`${moment.book}-${moment.chapter}`))
                return;
            } else {
              return;
            }
          }

          utils.doMoment(boxScore, moment);
          atLeastOneMoment = true;
          if (moment.type === skTypes.startOvertime && !includeOvertime) {
            overtimeRemoved = true;
            // Remove overtime stats; add points and errors, but still calculate record with the rest of the quiz
            boxScore.teams.forEach((team: skTypes.TeamBoxScore) => {
              const tId: string = team.teamId;
              statsResult[tId].quizzes.add(quiz.quizId);
              statsResult[tId].points += team.points;
              statsResult[tId].errors += team.errors;
            });
          }
          if (
            moment.team !== undefined &&
            (includeOvertime || boxScore.quizStatus !== skTypes.overtime)
          ) {
            const tId: string = quiz.teams[moment.team].teamId;
            if (utils.isQuestion(moment)) {
              const jumpType: skTypes.JumpType | "" = getJumpType(moment);
              if (
                settings.jumpTypes.size > 0 &&
                jumpType &&
                !settings.jumpTypes.has(jumpType)
              )
                return;

              statsResult[tId].questions.push({
                ...moment,
                eventName: event.eventName,
                eventId: event.eventId,
                round: quiz.round,
                site: quiz.site,
                quizName: quiz.quizName,
                quizId: quiz.quizId,
                qIndex,
              });

              // Questions by type
              const questionType = moment.errorType
                ? moment.errorType
                : moment.type;
              statsResult[tId].questionsByType[questionType] =
                (statsResult[tId].questionsByType[questionType] || 0) + 1;

              // Jump by type
              if (jumpType) {
                statsResult[tId].jumpsByType[jumpType] =
                  (statsResult[tId].jumpsByType[jumpType] || 0) + 1;
              }

              // K-Error by type
              const kErrorType = moment.kErrorType;
              if (kErrorType) {
                statsResult[tId].knowledgeErrorsByType[kErrorType] =
                  (statsResult[tId].knowledgeErrorsByType[kErrorType] || 0) + 1;
              }

              switch (moment.type) {
                case skTypes.tp:
                  statsResult[tId].correct += 1;
                  break;
                case skTypes.error:
                  statsResult[tId].errors += 1;
              }
            } else {
              statsResult[tId].otherMoments.push({
                ...moment,
                eventName: event.eventName,
                eventId: event.eventId,
                round: quiz.round,
                site: quiz.site,
                quizName: quiz.quizName,
                quizId: quiz.quizId,
                qIndex,
              });
            }
          }
        });

        if (atLeastOneMoment) {
          boxScore.teams.forEach(
            (team: skTypes.TeamBoxScore, index: number) => {
              const tId: string = team.teamId;
              if (!overtimeRemoved) {
                // If overtime had not been found in the quiz, add points and errors since that hadn't been done earlier
                statsResult[tId].quizzes.add(quiz.quizId);
                statsResult[tId].points += team.points;
              }
              if (boxScore.finalPlacements[index])
                statsResult[tId].record[
                  boxScore.finalPlacements[index] - 1
                ] += 1;
              if (team.errors > 3 && team.errors <= 6)
                statsResult[tId].pointsLostToErrors += (team.errors - 3) * 10;
              if (team.errors > 6)
                statsResult[tId].pointsLostToErrors +=
                  30 + (team.errors - 6) * 20;
            }
          );
        }
      });
    });
    return { stats: Object.values(statsResult), books: Array.from(books) };
  }
};

const getDefaultChapterStats = (
  book: number,
  chapter: number
): skTypes.ChapterStats => {
  return {
    book,
    chapter,
    questionsByType: {},
    jumpsByType: {},
    questions: [],
    luck: {
      expected: 0,
      actual: 0,
    },
  };
};
export const getChapterStats = (
  seasonId: string,
  settings: skTypes.StatsSettings,
  material: Book[]
): skTypes.ChapterStats[] => {
  const includeOvertime = settings.includeOvertime;
  const season: skTypes.Season | undefined = utils.getLocalSeason(seasonId);
  if (!season) {
    return [];
  } else {
    // We have the season; calculate stats
    const statsResult: Record<string, skTypes.ChapterStats> = {};
    season.events.forEach((event: skTypes.Event) => {
      event.quizzes.forEach((quizSummary: skTypes.QuizSummary) => {
        const quiz: skTypes.Quiz | undefined = utils.getLocalQuiz(
          quizSummary.quizId
        );
        if (
          !quiz ||
          (settings.quizIds.size > 0 && !settings.quizIds.has(quiz.quizId)) ||
          (settings.quizmasters.size > 0 &&
            (!quiz.quizmaster || !settings.quizmasters.has(quiz.quizmaster)))
        )
          return;

        // Check for team criteria
        let foundTeamCount: number = 0;
        quiz.teams.forEach((team: skTypes.Team) => {
          if (settings.teamIds.has(team.teamId)) foundTeamCount += 1;
        });
        if (foundTeamCount < settings.minTeams) return;

        let foundOvertime: boolean = false;
        let afterPause: boolean = true;
        // Go through the quiz, putting together the box score and getting per-moment data
        loopMoments(quiz.moments, (moment, momentIndex, qIndex) => {
          // Possible skipping because of pause
          if (settings.afterPause) {
            const skip = !afterPause;
            afterPause = ![skTypes.tp, skTypes.error, skTypes.foul].includes(
              moment.type
            );
            if (hasSub(moment, quiz.moments[momentIndex + 1]))
              afterPause = true;
            if (skip) return;
          }
          if (
            settings.quoteOnly &&
            (!moment.question || !moment.question.includes("Quote"))
          )
            return;

          if (!includeOvertime && foundOvertime) return;
          if (moment.type === skTypes.startOvertime) foundOvertime = true;
          // Check for quizzers on chairs
          let foundQuizzers: number = 0;
          if (moment.onBench) {
            moment.onBench.forEach((quizzerOnBench: string) => {
              if (!settings.quizzerIds.has(quizzerOnBench)) foundQuizzers += 1;
            });
          } else if (moment.onSeats) {
            moment.onSeats.forEach((quizzerOnSeat: string) => {
              if (settings.quizzerIds.has(quizzerOnSeat)) foundQuizzers += 1;
            });
          }
          if (foundQuizzers < settings.minQuizzers) return;
          if (settings.chapters.size > 0) {
            if (moment.book !== undefined && moment.chapter !== undefined) {
              if (!settings.chapters.has(`${moment.book}-${moment.chapter}`))
                return;
            } else {
              return;
            }
          }

          if (
            utils.isQuestion(moment) &&
            moment.book !== undefined &&
            moment.chapter !== undefined
          ) {
            const jumpType: skTypes.JumpType | "" = getJumpType(moment);
            if (
              settings.jumpTypes.size > 0 &&
              jumpType &&
              !settings.jumpTypes.has(jumpType)
            )
              return;

            const code: string = `${moment.book}-${moment.chapter}`;
            if (!statsResult[code])
              statsResult[code] = getDefaultChapterStats(
                moment.book,
                moment.chapter
              );
            if (jumpType)
              statsResult[code].jumpsByType[jumpType] =
                (statsResult[code].jumpsByType[jumpType] || 0) + 1;
            statsResult[code].questionsByType[moment.type] =
              (statsResult[code].questionsByType[moment.type] || 0) + 1;
            const { question: questionLuck } = getExpectedCorrect(
              moment,
              material,
              {}
            );
            if (questionLuck > 0 && questionLuck < 1) {
              statsResult[code].luck.expected += questionLuck;
              if (moment.type === skTypes.tp)
                statsResult[code].luck.actual += 1;
            }
            statsResult[code].questions.push({
              ...moment,
              eventName: event.eventName,
              eventId: event.eventId,
              round: quiz.round,
              site: quiz.site,
              quizName: quiz.quizName,
              quizId: quiz.quizId,
              qIndex,
            });
          }
        });
      });
    });
    return Object.values(statsResult);
  }
};

interface QuestionHistory {
  questionsByPeriod: number[]; // index 0=first half, 1=second half, 2=first overtime, etc
  ended: boolean; // If the quiz has ended
}
export const getQuestionsByPeriod = (
  moments: skTypes.Moment[]
): QuestionHistory => {
  const questionsByPeriod = [0]; // at least the first half
  let ended: boolean = false;
  moments.forEach((moment: skTypes.Moment) => {
    if (utils.isQuestion(moment)) {
      questionsByPeriod[questionsByPeriod.length - 1] += 1;
    }
    if (utils.isStartPeriod(moment)) {
      questionsByPeriod.push(0);
    }
    if (moment.type === skTypes.endQuiz) ended = true;
  });
  return { questionsByPeriod, ended };
};
export const getEstimatedQuestionsRemaining = (
  questions: QuestionHistory,
  currentQuestion?: number,
  period?: number
): number => {
  const { questionsByPeriod, ended } = questions;
  const questionCount =
    currentQuestion !== undefined
      ? currentQuestion
      : questionsByPeriod.reduce((a, b) => a + b); // Default to the last state of the quiz
  if (period === questionsByPeriod.length) return 0; // The quiz has ended if this is the case
  const currentPeriod =
    period !== undefined ? period : questionsByPeriod.length - 1;
  if (ended) {
    // Base things off actual data
    if (currentPeriod < 2) {
      // Regulation
      return (
        (questionsByPeriod[0] || 0) +
        (questionsByPeriod[1] || 0) -
        questionCount
      );
    } else {
      // Overtime
      let totalQuestions: number = 0;
      for (let i = 0; i <= currentPeriod; i++) {
        totalQuestions += questionsByPeriod[i] || 0;
      }
      return totalQuestions - questionCount;
    }
  } else {
    // Guess remaining questions
    let result = 0;
    switch (currentPeriod) {
      case 0:
        // First half
        result = 45 - questionCount;
        break;
      case 1:
        // Second half
        result = questionsByPeriod[0] * 2 - questionCount;
        break;
      default:
        // Overtime: base off of questions from regulation
        result = Math.floor(
          ((questionsByPeriod[0] + questionsByPeriod[1]) * 7 -
            questionCount * 6) /
            6
        );
        break;
    }
    return Math.max(result, 2);
  }
};
export const getWinProbability = (
  boxScore: skTypes.BoxScore,
  remainingQuestions: number,
  extraSimulations?: boolean,
  replaySettings?: skTypes.ReplayQuizSettings
): skTypes.WinProbability[] => {
  const iterations: number = extraSimulations ? 2000 : 1400;
  const winProbabilityRaw: skTypes.WinProbability[] = boxScore.teams.map(
    () => ({
      firstProb: 0,
      totalProb: 0,
    })
  );

  // Assemble people who can jump
  const jumperQuizzerIndex: string[][] = [];
  let jumperTeamIndex: number[] = [];
  boxScore.teams.forEach((team: skTypes.TeamBoxScore, teamIndex: number) => {
    jumperQuizzerIndex.push([]);
    team.lineup.forEach((quizzerId) => {
      const quizzer = team.quizzers[quizzerId];
      if (quizzer.eligable) {
        jumperQuizzerIndex[teamIndex].push(quizzerId);
        if (jumperQuizzerIndex[teamIndex].length <= 5)
          jumperTeamIndex.push(teamIndex);
      }
    });
  });

  const addQuestion = (
    box: skTypes.BoxScore,
    team: number,
    quizzer: number,
    type: skTypes.QuestionMomentType,
    jumperQuizzers: string[][],
    jumperTeams: number[],
    jumperIndex?: number
  ): void => {
    utils.doMoment(box, {
      team,
      quizzerId: jumperQuizzers[team][quizzer],
      type,
    });

    // If someone quizzed out or errored out
    if (!box.teams[team].quizzers[jumperQuizzers[team][quizzer]].eligable) {
      if (jumperQuizzers[team].length <= 5 && jumperIndex !== undefined)
        jumperTeams.splice(jumperIndex, 1);
      jumperQuizzers[team].splice(quizzer, 1);
    }
  };

  // Iterate
  for (let i = 0; i < iterations; i++) {
    // Deep copy of box score and jumpers data
    const box: skTypes.BoxScore = {
      ...boxScore,
      teams: [...boxScore.teams],
    };
    for (let j = 0; j < box.teams.length; j++) {
      box.teams[j] = {
        ...box.teams[j],
        quizzers: { ...box.teams[j].quizzers },
      };
      for (let k = 0; k < box.teams[j].lineup.length; k++) {
        box.teams[j].quizzers[box.teams[j].lineup[k]] = {
          ...box.teams[j].quizzers[box.teams[j].lineup[k]],
        };
      }
    }

    // Deep copy of jumper indexes
    let teamJumperIndex: number[] = [...jumperTeamIndex];
    const jumpers = [...jumperQuizzerIndex];
    for (let j = 0; j < jumpers.length; j++) {
      jumpers[j] = [...jumperQuizzerIndex[j]];
    }

    // Remaining Questions, if applicable
    if (replaySettings) {
      replaySettings.teams.forEach(
        (team: skTypes.ReplayQuizTeam, index: number) => {
          if (box.teams[index].eligable && team.setJumps) {
            // Assign remaining points and errors for this team
            for (let i = 0; i < team.correctAnswers; i++) {
              const quizzerWonJump = Math.floor(
                Math.random() * jumpers[index].length
              );
              addQuestion(
                box,
                index,
                quizzerWonJump,
                skTypes.tp,
                jumpers,
                teamJumperIndex
              );
            }
            for (let i = 0; i < team.errors; i++) {
              const quizzerWonJump = Math.floor(
                Math.random() * jumpers[index].length
              );
              addQuestion(
                box,
                index,
                quizzerWonJump,
                skTypes.error,
                jumpers,
                teamJumperIndex
              );
            }

            // Remove this team from jumping without actually making them inelgible
            jumpers[index] = [];
            teamJumperIndex = teamJumperIndex.filter(
              (teamIndex: number) => teamIndex !== index
            );
          }
        }
      );
    }

    // Remaining questions in the quiz
    for (let j = 0; j < remainingQuestions; j++) {
      // Determine who won the jump
      const jumperIndex = Math.floor(Math.random() * teamJumperIndex.length);
      const teamWonJump = teamJumperIndex[jumperIndex];
      const quizzerWonJump = Math.floor(
        Math.random() * (jumpers[teamWonJump]?.length || 0)
      );
      const wonJump = jumpers[teamWonJump]?.[quizzerWonJump];
      if (wonJump === undefined) continue; // Realistically shouldn't happen, but this protects against crashes

      // Apply 20 points or error. Assume 55% chance of 20 Points, 45% chance of error
      addQuestion(
        box,
        teamWonJump,
        quizzerWonJump,
        Math.random() > 0.55 ? skTypes.error : skTypes.tp,
        jumpers,
        teamJumperIndex,
        jumperIndex
      );
    }

    // Calculate end of quiz placements, with split placements
    utils.applyEndQuiz(box, true);
    box.finalPlacements.forEach((placement: number, teamIndex: number) => {
      winProbabilityRaw[teamIndex].totalProb += 3 - placement;
      if (placement === 1) winProbabilityRaw[teamIndex].firstProb += 1;
      if (placement === 1.5) winProbabilityRaw[teamIndex].firstProb += 0.5;
    });
  }

  // Divide by iteration cound for firstProb between 0 and 1, and totalProb between 0 and 2
  return winProbabilityRaw.map((winProbability: skTypes.WinProbability) => ({
    firstProb: winProbability.firstProb / iterations,
    totalProb: winProbability.totalProb / iterations,
  }));
};
export const getAllWinProbabilities = (
  moments: skTypes.Moment[],
  teams: skTypes.Team[]
): skTypes.WinProbability[][] => {
  const box: skTypes.BoxScore = utils.getDefaultBoxScore(teams);
  const result: skTypes.WinProbability[][] = [];

  const questions = getQuestionsByPeriod(moments);
  let currentPeriod: number = 0;
  moments.forEach((moment: skTypes.Moment) => {
    const isQuestion: boolean = utils.isQuestion(moment);
    // Probabilities to start the quiz
    if (isQuestion && result.length === 0) {
      result.push(
        getWinProbability(
          box,
          getEstimatedQuestionsRemaining(
            questions,
            result.length,
            currentPeriod
          )
        )
      );
    }

    // One question at a time
    if (moment.type === skTypes.endQuiz) {
      // The quiz just ended, so replace the previous entry with one where there will be zero questions remaining.
      result[result.length - 1] = getWinProbability(box, 0);
    } else {
      utils.doMoment(box, moment);
    }
    if (utils.isStartPeriod(moment)) currentPeriod += 1;
    if (isQuestion)
      result.push(
        getWinProbability(
          box,
          getEstimatedQuestionsRemaining(
            questions,
            result.length - 1,
            currentPeriod
          )
        )
      );
  });
  return result;
};

export const getMaterialKnown = (
  season: skTypes.Season | undefined,
  teamIds: string[]
): skTypes.MaterialKnown => {
  const result: skTypes.MaterialKnown = {};
  if (!season) return result;
  const teamSet = new Set(teamIds);
  season.events.forEach((event) => {
    event.quizzes.forEach((summary) => {
      if (!summary.teams.some((team) => teamSet.has(team.teamId))) return;
      const quiz: skTypes.Quiz | undefined = utils.getLocalQuiz(summary.quizId);
      if (!quiz) return;
      quiz.moments.forEach((moment) => {
        if (utils.isQuestion(moment)) {
          if (moment.errorType === "L") return;
          if (
            moment.kErrorType &&
            ["TIME", "WRONG_VERSE"].includes(moment.kErrorType)
          )
            return;
          const qId = moment.quizzerId;
          const book = moment.book,
            chapter = moment.chapter;
          if (book === undefined || chapter === undefined) return;
          if (!qId) return;
          if (!result[qId]) result[qId] = { books: {} };
          if (!result[qId].books[book])
            result[qId].books[book] = new Set<number>();
          result[qId].books[book].add(chapter);
        }
      });
    });
  });
  return result;
};
