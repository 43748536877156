export const SET_APP_DETAILS = "SET_APP_DETAILS";
export const SET_REQUEST_LOADING = "SET_REQUEST_LOADING";
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT";

interface AppDetails {
  version: string;
  usage: { date: string; p: number }[];
  allWeeksUsage: { d: string; p: number }[];

  requestLoading: boolean;
  requestSuccess: boolean;
  errorMessage: string;
  returnCode: number;
}
const initialState: AppDetails = {
  version: "",
  usage: [],
  allWeeksUsage: [],

  requestLoading: false,
  requestSuccess: false,
  errorMessage: "",
  returnCode: 0,
};

export interface SetAppDetails {
  type: typeof SET_APP_DETAILS;
  payload: {
    version: string;
    usage: any[];
    allWeeksUsage: any[];
  };
}
export interface SetRequestLoading {
  type: typeof SET_REQUEST_LOADING;
}
export interface SetRequestResult {
  type: typeof SET_REQUEST_RESULT;
  payload: {
    success: boolean;
    errorMessage?: string;
    returnCode: number;
  };
}

export type AppDetailsActions =
  | SetAppDetails
  | SetRequestLoading
  | SetRequestResult;

export default function appDetails(
  state = initialState,
  action: AppDetailsActions
) {
  switch (action.type) {
    case SET_APP_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_REQUEST_LOADING:
      return {
        ...state,
        requestLoading: true,
      };
    case SET_REQUEST_RESULT:
      return {
        ...state,
        requestLoading: false,
        requestSuccess: action.payload.success,
        errorMessage: action.payload.errorMessage || "",
        returnCode: action.payload.returnCode,
      };
    default:
      return state;
  }
}
