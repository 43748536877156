import React from "react";
import store, { RootStore } from "./state/store";
import { BrowserRouter } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import MaterialProvider from "./MaterialProvider";
import MainRouter from "./pages/MainRouter";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import { ErrorHandler } from "./components/ErrorHandler";
import "./App.scss";

export default function App() {
  return (
    <Provider store={store}>
      <ApplicationProvider />
    </Provider>
  );
}

export const ApplicationProvider = () => {
  const settings = useSelector((state: RootStore) => state.settings);
  const theme = createTheme({
    palette: {
      mode: settings.lightMode ? "light" : "dark",
      primary: {
        main: settings.lightMode ? "#add3e8" : "#1c00ff",
      },
      secondary: {
        main: settings.lightMode ? "#1c00ff" : "#add3e8",
      },
      background: {
        default: settings.lightMode ? "white" : "black",
      },
    },
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: settings.lightMode ? "#add3e8" : "#0f0570",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className={`app ${settings.lightMode ? "light-mode" : "dark-mode"}`}
          //style={{ minHeight: "100vh" }}
        >
          <MaterialProvider>
            <BrowserRouter>
              <ErrorHandler>
                <MainRouter />
              </ErrorHandler>
            </BrowserRouter>
          </MaterialProvider>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
