import { useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "../../components/Row";
import {
  calculateAchievements,
  importTournamentDetails,
  importTournamentEvent,
  setTournamentAsCurrent,
  setTournamentAsNotCurrent,
} from "../../state/actions/tournamentActions";
import { useRequestHandler } from "../../hooks/requestHandler";
import { Check } from "../../components/Check/Check";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { DateInput, NumberInput, StringInput } from "../../components/Inputs";

export function TournamentAdmin() {
  useDocumentTitle("Admin Tools - BQA Tournaments");
  const handler = useRequestHandler({});

  const [adminDetails, setAdminDetails] = useState<any>({
    tournamentId: "",
    secondaryId: "",
    account: "",
    seasonId: 0,
    eventId: 0,
    eventName: "",
    order: "",
    seasonType: "local",
    allMinistry: "",
    teamsExclude: "",
  });

  return (
    <div className="page">
      <Row>
        <Link className="back-button" to="/tournaments">
          Go Back
        </Link>
        <h1 style={{ marginLeft: 10 }}>Tournament Admin Tools</h1>
      </Row>
      <p>
        Use this page for admin tools, to import details from quizzing.live and
        other various tools.
      </p>
      <Link to="/tournaments/usage">Recent Usage</Link>
      <hr />
      <h3>Calculate Achievements</h3>
      <p>
        This recalculates achievements for all quizzers, and returns in the
        Network tab total experience for all quizzers and teams, even those that
        do not have a page.
      </p>
      <button
        className="clickable"
        onClick={() => {
          handler.runRequest(
            calculateAchievements(),
            "Calculating achievements...",
            "Achievements calculated! Stats in the network tab."
          );
        }}
      >
        Calculate
      </button>
      <hr />
      <h3>Import Tournament Details</h3>
      <p>This imports rosters and stats from quizzing.live.</p>
      <StringInput
        caption="Tournament ID:"
        value={adminDetails.tournamentId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, tournamentId: value })
        }
      />
      <StringInput
        caption="Secondary ID:"
        value={adminDetails.secondaryId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, secondaryId: value })
        }
      />
      <StringInput
        caption="Account:"
        value={adminDetails.account}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, account: value })
        }
        belowText="From quizzing.live"
      />
      <NumberInput
        caption="Season ID:"
        value={adminDetails.seasonId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, seasonId: value })
        }
        belowText="From quizzing.live"
      />
      <button
        className="clickable"
        style={{ marginTop: 20 }}
        onClick={() => {
          handler.runRequest(
            importTournamentDetails(
              adminDetails.tournamentId,
              adminDetails.secondaryId,
              adminDetails.account,
              adminDetails.seasonId
            ),
            "Importing details...",
            "Details imported!"
          );
        }}
      >
        Import Details
      </button>

      <hr />

      <h3>Import Tournament Event</h3>
      <p>This imports an event from quizzing.live.</p>
      <StringInput
        caption="Tournament ID:"
        value={adminDetails.tournamentId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, tournamentId: value })
        }
      />
      <StringInput
        caption="Secondary ID:"
        value={adminDetails.secondaryId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, secondaryId: value })
        }
      />
      <StringInput
        caption="Account:"
        value={adminDetails.account}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, account: value })
        }
        belowText="From quizzing.live"
      />
      <NumberInput
        caption="Season ID:"
        value={adminDetails.seasonId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, seasonId: value })
        }
        belowText="From quizzing.live"
      />
      <NumberInput
        caption="Event ID:"
        value={adminDetails.eventId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, eventId: value })
        }
        belowText="From quizzing.live"
      />
      <StringInput
        caption="Event Name:"
        value={adminDetails.eventName}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, eventName: value })
        }
      />
      <DateInput
        caption="Date:"
        value={adminDetails.date}
        onChange={(value) => setAdminDetails({ ...adminDetails, date: value })}
      />
      <NumberInput
        caption="Order:"
        value={adminDetails.order}
        onChange={(value) => setAdminDetails({ ...adminDetails, order: value })}
        belowText="Optional - Lower numbers mean earlier in the day"
      />
      <button
        className="clickable"
        style={{ marginTop: 20 }}
        onClick={() => {
          handler.runRequest(
            importTournamentEvent(
              adminDetails.tournamentId,
              adminDetails.secondaryId,
              adminDetails.account,
              adminDetails.seasonId,
              adminDetails.eventId,
              adminDetails.eventName,
              adminDetails.date,
              adminDetails.order
                ? Number.parseInt(adminDetails.order)
                : undefined
            )
          );
        }}
      >
        Import Event
      </button>

      <hr />
      <h3>Set Tournament as Current</h3>
      <p>
        For the "Currently Quizzing" displays for teams, quizzers, and
        ministries, this sets a tournament as the current.
      </p>
      <StringInput
        caption="Tournament ID:"
        value={adminDetails.tournamentId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, tournamentId: value })
        }
      />
      <StringInput
        caption="Secondary ID:"
        value={adminDetails.secondaryId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, secondaryId: value })
        }
      />
      <div style={{ marginTop: 5 }}>
        <Check
          checked={adminDetails.seasonType === "local"}
          onClick={() =>
            setAdminDetails({ ...adminDetails, seasonType: "local" })
          }
          style={{ width: 150 }}
        >
          Local
        </Check>
        <Check
          checked={adminDetails.seasonType === "postseason"}
          onClick={() =>
            setAdminDetails({ ...adminDetails, seasonType: "postseason" })
          }
          style={{ width: 150, marginLeft: 5 }}
        >
          Postseason
        </Check>
      </div>
      <StringInput
        caption="All Ministry:"
        value={adminDetails.allMinistry}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, allMinistry: value })
        }
        belowText="Sets for all teams"
      />
      <StringInput
        caption="Exclude:"
        value={adminDetails.teamsExclude}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, teamsExclude: value })
        }
        belowText="Separate with commas - removes these quizzers and teams"
      />
      <button
        className="clickable"
        style={{ marginTop: 20 }}
        onClick={() => {
          handler.runRequest(
            setTournamentAsCurrent(
              adminDetails.tournamentId,
              adminDetails.secondaryId,
              adminDetails.seasonType,
              adminDetails.allMinistry,
              adminDetails.teamsExclude.split(",")
            ),
            "Setting the tournament as current...",
            "Success!"
          );
        }}
      >
        Set as Current
      </button>

      <hr />

      <h3>Set as Not Current</h3>
      <p>
        Opposite of the above. This remove the currently quizzing from all
        quizzers and teams, if their active status was still with this event
      </p>
      <StringInput
        caption="Tournament ID:"
        value={adminDetails.tournamentId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, tournamentId: value })
        }
      />
      <StringInput
        caption="Secondary ID:"
        value={adminDetails.secondaryId}
        onChange={(value) =>
          setAdminDetails({ ...adminDetails, secondaryId: value })
        }
      />
      <button
        className="clickable"
        style={{ marginTop: 20 }}
        onClick={() => {
          handler.runRequest(
            setTournamentAsNotCurrent(
              adminDetails.tournamentId,
              adminDetails.secondaryId
            ),
            "Setting the tournament as not current...",
            "Success!"
          );
        }}
      >
        Set as Not Current
      </button>
      {handler.snackbar}
    </div>
  );
}
