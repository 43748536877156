import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRequestHandler } from "../../hooks/requestHandler";
import {
  authenticate,
  confirmUsername,
  createUser,
  requestPasswordReset,
} from "../../state/actions/authentication";
import styles from "./Authentication.module.css";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { StringInput } from "../../components/Inputs";

export function Login() {
  useDocumentTitle("Login - Bible Quiz Academy");
  type Screens = "LOGIN" | "PWD" | "USERNAME" | "CHALLENGE" | "CREATE";
  const navigate = useNavigate();
  const location = useLocation();
  const screen = location.state?.screen || "LOGIN";

  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [formData, setFormData] = useState<any>({});
  const setScreen = (newScreen: Screens) => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    navigate(location.pathname, { state: { screen: newScreen } });
    setFormData({});
  };

  const handler = useRequestHandler({
    onSuccess: (key) => {
      if (key === "LOGIN") {
        setTimeout(() => {
          navigate("/");
        }, 300);
      }
    },
  });

  const getMainContent = () => {
    switch (screen) {
      case "LOGIN":
        return (
          <>
            <StringInput
              caption="Username:"
              placeholder="Username"
              value={formData.username}
              onChange={(value) =>
                setFormData({ ...formData, username: value })
              }
            />
            <StringInput
              caption="Password:"
              placeholder="Password"
              value={formData.password}
              onChange={(value) =>
                setFormData({ ...formData, password: value })
              }
              type="password"
            />
            <p>
              You don't have to have an account to use BQA, but it will make
              some tools such as scorekeeping easier, and your settings will be
              remembered if you log in on another device.
            </p>
            {errorMessage && (
              <p className="important-information">{errorMessage}</p>
            )}
            <div style={{ marginTop: 10 }}>
              <button
                className="clickable"
                onClick={() => {
                  handler.runRequest(
                    authenticate(formData.username, formData.password),
                    "Logging in...",
                    "You are now logged in!",
                    "LOGIN"
                  );
                }}
              >
                Login
              </button>
            </div>
            <div style={{ marginTop: 10 }}>
              <button className="link" onClick={() => setScreen("USERNAME")}>
                Forgot username?
              </button>
              <button
                className="link"
                onClick={() => setScreen("PWD")}
                style={{ marginLeft: 20 }}
              >
                Forgot password?
              </button>
            </div>
            <div style={{ marginTop: 10 }}>
              <button
                className="link"
                onClick={() => {
                  setScreen("CREATE");
                  setFormData({});
                }}
              >
                Create an Account
              </button>
            </div>
          </>
        );
      case "PWD":
        if (successMessage) {
          return (
            <>
              <p>{successMessage}</p>
              <button className="link">Reset your password</button>
            </>
          );
        }
        return (
          <>
            <p>Enter the username or email address of your BQA account.</p>
            {errorMessage && (
              <p className="important-information">{errorMessage}</p>
            )}
            <StringInput
              caption="Username:"
              value={formData.usernameOrPassword}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  usernameOrPassword: value,
                })
              }
            />
            <div style={{ marginTop: 20 }}>
              <button
                className="clickable"
                onClick={() => {
                  handler.runRequest(
                    requestPasswordReset(formData.usernameOrPassword),
                    "Resetting your password...",
                    "An email has been sent with instructions to reset your password!",
                    "RESET"
                  );
                }}
              >
                Reset Password
              </button>
              <button
                className="link"
                style={{ marginLeft: 20 }}
                onClick={() => setScreen("LOGIN")}
              >
                Back to Login
              </button>
            </div>
          </>
        );
      case "CREATE":
        return (
          <>
            <StringInput
              caption="Username:"
              value={formData.username}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  username: value,
                })
              }
              inputStyle={{ width: 300 }}
            />
            <StringInput
              caption="Email:"
              value={formData.email}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  email: value,
                })
              }
              inputStyle={{ width: 300 }}
            />
            <div style={{ marginTop: 10 }}>
              <button
                className="clickable"
                onClick={() => {
                  handler.runRequest(
                    createUser(formData.username, formData.email),
                    "Creating a new user...",
                    "You have been sent an email with instructions to set up your password",
                    "CREATE"
                  );
                }}
              >
                Create User
              </button>
            </div>
            <div style={{ marginTop: 15 }}>
              <button
                className="link"
                onClick={() => {
                  setScreen("LOGIN");
                  setFormData({});
                }}
              >
                Back to Login
              </button>
            </div>
          </>
        );
      case "USERNAME":
        if (successMessage) {
          return (
            <>
              <p>{successMessage}</p>
              <button className="link">Back to Login</button>
            </>
          );
        }
        return (
          <>
            <p>Enter the email address of your BQA account.</p>
            {errorMessage && (
              <p className="important-information">{errorMessage}</p>
            )}
            <StringInput
              caption="Email:"
              value={formData.email}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  email: value,
                })
              }
              inputStyle={{ width: 300 }}
            />
            <p>
              An email will be sent to your address telling you what your
              username is.
            </p>
            <div style={{ marginTop: 20 }}>
              <button
                className="clickable"
                onClick={() => {
                  handler.runRequest(
                    confirmUsername(formData.email),
                    "You have been emailed your username."
                  );
                }}
              >
                Find Username
              </button>
            </div>
            <div style={{ marginTop: 20 }}>
              <button className="link" onClick={() => setScreen("LOGIN")}>
                Back to Login
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <div className="page">
      <div className={styles.screen_container}>
        <div style={{ marginBottom: 20 }}>
          <button className="link" onClick={() => navigate("/")}>
            Go Back
          </button>
        </div>
        {getMainContent()}
      </div>
      {handler.snackbar}
    </div>
  );
}
