import { Dispatch } from "redux";
import {
  PageSearch,
  QuizzerPage,
  TEvent,
  TQuiz,
  TeamPage,
  TournamentPage,
  TournamentPageID,
  TournamentSummary,
  TournamentTiers,
} from "../../types/tournamentTypes";
import { ThunkActionType } from "../store";
import { fetchWrapper } from "./fetchWrapper";

export type TournamentActionTypes =
  | SetTournamentsLoading
  | SetTournamentList
  | SetTournamentPage
  | SetTournamentEvent
  | SetTournamentSearch
  | SetTournamentsUsage;
export interface SetTournamentsLoading {
  type: "SET_TOURNAMENTS_LOADING";
  payload: {
    isLoading: boolean;
    success: boolean;
    errorMessage: string;
  };
}
export interface SetTournamentList {
  type: "SET_TOURNAMENT_LIST";
  payload: {
    tournaments: TournamentSummary[];
    trending: TournamentPageID[];
  };
}
export interface SetTournamentPage {
  type: "SET_TOURNAMENT_PAGE";
  payload: {
    page?: TournamentPage;
    trending?: TournamentPageID[];
  };
}
export interface SetTournamentEvent {
  type: "SET_TOURNAMENT_EVENT";
  payload: {
    event: TEvent;
  };
}
export interface SetTournamentSearch {
  type: "SET_TOURNAMENT_SEARCH";
  payload: {
    pages: PageSearch[];
  };
}
export interface SetTournamentsUsage {
  type: "SET_TOURNAMENTS_USAGE";
  payload: {
    usage: any[];
  };
}

export const tournamentSearch =
  (text: string) => async (dispatch: Dispatch<TournamentActionTypes>) => {
    dispatch(setTournamentsLoading(true));
    const data = await fetchWrapper(
      dispatch,
      "GET",
      `tournaments/search/${text}`
    );
    dispatch(setTournamentSearch(data.responseMessage?.pages || []));
    if (data.returnCode !== 0) {
      dispatch(setTournamentsLoading(false, false, data.errorMessage));
    }
  };
export const getTournamentList =
  (): ThunkActionType => async (dispatch: Dispatch<TournamentActionTypes>) => {
    dispatch(setTournamentsLoading(true));
    const data = await fetchWrapper(
      dispatch,
      "GET",
      "tournaments/getalltournaments"
    );
    if (data.returnCode === 0) {
      dispatch(
        setTournamentList(
          data.responseMessage.tournaments,
          data.responseMessage.trending
        )
      );
    } else {
      dispatch(setTournamentsLoading(false, false, data.errorMessage));
    }
  };

export const getPage = async (
  pageId: string,
  secondaryId?: string
): Promise<TournamentPage | undefined> => {
  const data = await fetchWrapper(
    undefined,
    "GET",
    `tournaments/getpage/${pageId}${
      secondaryId ? `/${secondaryId}` : ""
    }`.replace(/ /g, "_")
  );
  if (data.returnCode === 0 || data.returnCode === 4001) {
    return data.responseMessage.page;
  } else {
    return undefined;
  }
};

export const sendContact = (contact: any): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    await fetchWrapper(dispatch, "POST", `tournaments/contact`, { contact });
  };
};

export const savePage = (
  mode: "CREATE" | "UPDATE",
  page: Partial<TournamentPage>
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    await fetchWrapper(
      dispatch,
      mode === "CREATE" ? "PUT" : "PATCH",
      `tournaments/${mode.toLowerCase()}page`,
      { page: { ...page, events: undefined } }
    );
  };
};

export const importTournamentStats = (
  tournamentId: string,
  secondaryId: string | undefined,
  team: string[],
  quizzer: string[]
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    await fetchWrapper(dispatch, "POST", `tournaments/importstats`, {
      tournamentId,
      secondaryId,
      team,
      quizzer,
    });
  };
};

export const saveTournamentQuiz = (
  action: "CREATE" | "UPDATE" | "DELETE",
  pageId: string,
  secondaryId: string | undefined,
  eventName: string,
  quiz: TQuiz
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    const result = await fetchWrapper(
      dispatch,
      "PATCH",
      "tournaments/updateeventquiz",
      { pageId, secondaryId, eventName, quiz, action }
    );
    if (result.returnCode === 0) {
      dispatch(setTournamentEvent(result.responseMessage.event));
    }
  };
};

export const createTournamentEvent = (
  tournamentId: string,
  secondaryId: string,
  event: Partial<TEvent>
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "PUT", "tournaments/createevent", {
      tournamentId,
      secondaryId,
      event,
    });
  };
};
export const updateTournamentEvent = (
  tournamentId: string,
  secondaryId: string,
  event: Partial<TEvent>
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "PATCH", "tournaments/updateeventdetails", {
      tournamentId,
      secondaryId,
      event: {
        ...event,
        quizzes: undefined,
      },
    });
  };
};

export const importTournamentDetails = (
  tournamentId: string,
  secondaryId: string,
  account: string,
  seasonId: number = 0,
  settings: any = {}
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "POST", "tournaments/importdetails", {
      tournamentId,
      secondaryId,
      account,
      seasonId,
      settings,
    });
  };
};
export const importTournamentEvent = (
  tournamentId: string,
  secondaryId: string,
  account: string,
  seasonId: number,
  eventId: number,
  eventName: string,
  date: string,
  order?: number
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "POST", "tournaments/importevent", {
      tournamentId,
      secondaryId,
      account,
      seasonId,
      eventId,
      eventName,
      date,
      order,
    });
  };
};

export const setTournamentAsCurrent = (
  tournamentId: string,
  secondaryId: string,
  seasonType: "local" | "postseason",
  allMinistry: string,
  excludeList: string[]
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "POST", "tournaments/setascurrent", {
      tournamentId,
      secondaryId,
      seasonType,
      allMinistry,
      excludeList,
    });
  };
};
export const setTournamentAsNotCurrent = (
  tournamentId: string,
  secondaryId: string
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "POST", "tournaments/setasnotcurrent", {
      tournamentId,
      secondaryId,
    });
  };
};

export const calculateAchievements = (): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    fetchWrapper(dispatch, "POST", "tournaments/calculateachievements");
  };
};

export const getTournamentsUsage = (
  pageId: string,
  secondaryId: string
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    let url = "tournaments/getusage";
    if (pageId) url += `/${pageId}`;
    if (secondaryId) url += `/${secondaryId}`;
    url = url.replace(/ /g, "_");
    const data = await fetchWrapper(dispatch, "GET", url);
    if (data.returnCode === 0)
      dispatch(setTournamentsUsage(data.responseMessage.usage));
  };
};
export const getTournamentPageViews = (
  page: TournamentPage
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    const data = await fetchWrapper(
      dispatch,
      "GET",
      `tournaments/getpageviews/${page.pageId}${
        page.secondaryId ? `/${page.secondaryId}` : ""
      }`.replace(/ /g, "_")
    );
    if (data.returnCode === 0)
      dispatch(
        setSelectedPage({ ...page, timesPulled: data.responseMessage.views })
      );
  };
};
export const getTournamentResults = (
  page: QuizzerPage | TeamPage
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    const data = await fetchWrapper(
      dispatch,
      "GET",
      `tournaments/getresults/${page.pageType}/${page.pageId.replace(
        / /g,
        "_"
      )}`
    );
    if (data.returnCode === 0)
      dispatch(
        setSelectedPage({ ...page, results: data.responseMessage.results })
      );
  };
};
export const getTournamentHeadToHead = (
  page: QuizzerPage | TeamPage,
  opponent: string,
  tier: TournamentTiers = "MISC",
  minDate?: string,
  maxDate?: string
): ThunkActionType => {
  return async (dispatch: Dispatch) => {
    const data = await fetchWrapper(
      dispatch,
      "POST",
      `tournaments/getheadtohead`,
      {
        headToHeadType: page.pageType,
        person1: page.pageId,
        person2: opponent,
        tier,
        minDate,
        maxDate,
      }
    );
    if (data.returnCode === 0)
      dispatch(
        setSelectedPage({
          ...page,
          headToHead: data.responseMessage.headToHead,
        })
      );
  };
};

export const setTournamentsLoading = (
  isLoading: boolean,
  success: boolean = false,
  errorMessage: string = ""
): SetTournamentsLoading => ({
  type: "SET_TOURNAMENTS_LOADING",
  payload: {
    isLoading,
    success,
    errorMessage,
  },
});
export const setTournamentList = (
  tournaments: TournamentSummary[],
  trending: TournamentPageID[]
): SetTournamentList => ({
  type: "SET_TOURNAMENT_LIST",
  payload: {
    tournaments,
    trending,
  },
});
export const setSelectedPage = (page?: TournamentPage): SetTournamentPage => ({
  type: "SET_TOURNAMENT_PAGE",
  payload: {
    page,
  },
});
export const setTournamentEvent = (event: TEvent): SetTournamentEvent => ({
  type: "SET_TOURNAMENT_EVENT",
  payload: { event },
});
export const setTournamentSearch = (
  pages: PageSearch[]
): SetTournamentSearch => ({
  type: "SET_TOURNAMENT_SEARCH",
  payload: {
    pages,
  },
});
export const setTournamentsUsage = (usage: any[]): SetTournamentsUsage => ({
  type: "SET_TOURNAMENTS_USAGE",
  payload: {
    usage,
  },
});
