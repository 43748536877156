import { useState, useEffect, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../state/store";
import { Row } from "../components/Row";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Ruling } from "../components/Ruling";
import { DoubleClickable } from "../components/DoubleClickable";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { shuffle } from "../util/generalUtil";
import { blacklistedWords } from "../util/DANGERblacklistedWords";
import { swipeCutoff } from "../util/swiping";
import { Swiping } from "../types/generalTypes";
import { getTimeDisplay } from "../util/timeUtil";

const BEFOREHAND = "BEFOREHAND";
const READY = "READY";
const ANSWERING = "ANSWERING";
const RULING = "RULING";
const DONE = "DONE";
const verseCardsStateKey = "verse-cards-state";
type VerseCardsPhaseType =
  | typeof BEFOREHAND
  | typeof READY
  | typeof ANSWERING
  | typeof RULING
  | typeof DONE;
export interface VerseType {
  reference: string;
  verse: string;
  questions: string[];
}

export function VerseCards() {
  useDocumentTitle("Verse Cards - Bible Quiz Academy");
  const navigate = useNavigate();
  const { transcript, resetTranscript } = useSpeechRecognition();
  const material = useSelector((state: RootStore) => state.material.material);
  const settings = useSelector((state: RootStore) => state.settings);
  const chapters = settings.selectedChapters;
  const [randomized, setRandomized] = useState<VerseType[]>([]);
  const [phase, setPhase] = useState<VerseCardsPhaseType>(BEFOREHAND);
  const phaseRef = useRef<VerseCardsPhaseType>(BEFOREHAND);

  const swipeStatusDefault: Swiping = {
    sX: -1,
    sY: -1,
    eX: -1,
    eY: -1,
  };
  const [swipeStatus, setSwipeStatus] = useState<Swiping>(swipeStatusDefault);

  const stats = useRef<{
    totalVerses: number;
    correct: number;
    incorrect: number;
    time: number;
  }>({
    totalVerses: 0,
    correct: 0,
    incorrect: 0,
    time: 0,
  });
  const [startTimekeeper, setStartTimekeeper] = useState(0);
  const timekeeperId = useRef<any>(null);
  const timeTaken = useRef<number>(0);
  useEffect(() => {
    if (startTimekeeper) {
      const newId = setInterval(() => {
        timeTaken.current = timeTaken.current + 0.2;
      }, 200);
      timekeeperId.current = newId;
    }
    return () => clearTimeout(timekeeperId.current);
  }, [startTimekeeper]);

  useEffect(() => {
    if (material.length === 0) return;
    const allVerses: VerseType[] = [];
    material.forEach((book, index) => {
      book.chapters.forEach((chapter, chapterIndex) => {
        if (chapters && chapters[index] && chapters[index][chapterIndex]) {
          chapter.forEach((verse, verseIndex) => {
            const chapterFromSettings = chapters[index][chapterIndex];
            const chapterSettings =
              typeof chapterFromSettings === "boolean"
                ? Array(chapter.length).fill(chapterFromSettings)
                : chapterFromSettings;
            if (chapterSettings[verseIndex]) {
              allVerses.push({
                reference: `${book.bookName} ${chapterIndex + 1}:${
                  verseIndex + 1
                }`,
                ...verse,
              });
            }
          });
        }
      });
    });
    if (settings.vcRandomize) shuffle(allVerses);
    setRandomized(allVerses);
    setPhase(READY);
    phaseRef.current = READY;
    stats.current.totalVerses = allVerses.length;
  }, [material]);

  const getStyledVerse = (
    verse: string,
    compare: string,
    styleKey: "verse" | "spoken"
  ) => {
    if (!compare) return verse;
    const words = verse.split(" ");
    const compareWords = compare.split(" ");
    return (
      <span>
        {words.map((word, index) => {
          blacklistedWords.forEach((blacklisted) => {
            if (word.includes(blacklisted)) {
              const censored =
                blacklisted.substring(0, 1) +
                "*".repeat(blacklisted.length - 1);
              word = word.replace(blacklisted, censored);
            }
          });

          let included = false;
          compareWords.forEach((word2) => {
            if (
              word.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase() ===
              word2.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase()
            )
              included = true;
          });
          return (
            <span
              key={`styled-verse-${styleKey}-${index}`}
              style={{ color: included ? undefined : "red" }}
            >
              {word + " "}
            </span>
          );
        })}
      </span>
    );
  };

  const clickNextVerse = () => {
    setPhase(ANSWERING);
    phaseRef.current = ANSWERING;
    setStartTimekeeper(Math.random());
    timeTaken.current = 0;
    resetTranscript();
    if (settings.vcVoiceToText)
      SpeechRecognition.startListening({ continuous: true });
  };
  const clickShowAnswer = () => {
    setPhase(RULING);
    phaseRef.current = RULING;
    window.scrollTo(0, 0);
    clearTimeout(timekeeperId.current);
    SpeechRecognition.stopListening();
  };
  const makeRuling = (ruling: number) => {
    setRandomized((oldValue) => {
      const newRandomized = [...oldValue];
      if (ruling === 2 || (ruling === 1 && settings.vcReview)) {
        newRandomized.push(newRandomized[0]);
      }
      if (ruling === 0) stats.current.correct = stats.current.correct + 1;
      if (ruling === 1) stats.current.incorrect = stats.current.incorrect + 1;
      if (ruling !== 2) {
        stats.current.time = stats.current.time + timeTaken.current;
      }
      newRandomized.splice(0, 1);

      const newPhase = newRandomized.length > 0 ? READY : DONE;
      setPhase(newPhase);

      phaseRef.current = newPhase;

      localStorage.setItem(
        verseCardsStateKey,
        JSON.stringify({
          versesRemaining: newRandomized,
          stats: stats.current,
        })
      );

      return newRandomized;
    });
  };
  const upHandler = (e: any) => {
    if (e.code === "Space") {
      switch (phaseRef.current) {
        case READY:
          clickNextVerse();
          break;
        case ANSWERING:
          clickShowAnswer();
          break;
        case RULING:
          makeRuling(0);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keyup", upHandler);
      SpeechRecognition.stopListening();
    };
  }, []);

  if (chapters.length === 0) {
    return <Navigate to="/" />;
  }

  return (
    <DoubleClickable
      className="page"
      style={{
        padding: 20,
        touchAction: phase === RULING ? "none" : "auto",
        minHeight: "calc(100vh - 20px)",
        minWidth: 300,
      }}
      doubleClick={() => {
        if (settings.touchscreen) {
          if (phase === READY && randomized.length > 0) {
            clickNextVerse();
          }
          if (phase === ANSWERING) {
            clickShowAnswer();
          }
        }
      }}
      onTouchStart={(e) => {
        setSwipeStatus({
          ...swipeStatus,
          sX: e.touches[0].clientX,
          eX: e.touches[0].clientX,
          sY: e.touches[0].clientY,
          eY: e.touches[0].clientY,
        });
      }}
      onTouchMove={(e) => {
        setSwipeStatus({
          ...swipeStatus,
          eX: e.touches[0].clientX,
          eY: e.touches[0].clientY,
        });
      }}
      onTouchEnd={(e) => {
        if (settings.touchscreen && phase === RULING) {
          if (Math.abs(swipeStatus.eX - swipeStatus.sX) > swipeCutoff) {
            makeRuling(swipeStatus.eX > swipeStatus.sX ? 0 : 1);
          }
        }
        setSwipeStatus(swipeStatusDefault);
      }}
    >
      {phase === RULING && settings.touchscreen && swipeStatus.sX >= 0 && (
        <div
          style={{
            backgroundColor: swipeStatus.sX < swipeStatus.eX ? "green" : "red",
            height: 5,
            position: "absolute",
            left: Math.min(swipeStatus.sX, swipeStatus.eX),
            top: swipeStatus.sY - 5,
            width: Math.abs(swipeStatus.sX - swipeStatus.eX),
          }}
        />
      )}

      <div style={{ height: 250, marginTop: 10 }}>
        {phase === READY &&
          stats.current.correct === 0 &&
          stats.current.incorrect === 0 &&
          localStorage.getItem(verseCardsStateKey) && (
            <button
              className="link"
              onClick={() => {
                const previousState = JSON.parse(
                  localStorage.getItem(verseCardsStateKey) as string
                );
                setRandomized(previousState.versesRemaining);
                stats.current = previousState.stats;
              }}
            >
              Restore Previous State
            </button>
          )}
        {phase === ANSWERING && <div>{randomized[0].reference}</div>}
        {phase === RULING && (
          <div>
            {randomized[0].reference}
            <br />
            <br />
            {getStyledVerse(randomized[0].verse, transcript, "verse")}
            <br />
            <br />
            {
              //transcript
              getStyledVerse(transcript, randomized[0].verse, "spoken")
            }
          </div>
        )}
      </div>
      <div style={{ height: 80 }}>
        {phase === READY &&
          randomized.length > 0 &&
          (settings.touchscreen ? (
            <p className="important-information">
              Double-tap to see the next verse.
            </p>
          ) : (
            <button
              className="clickable"
              onClick={() => {
                clickNextVerse();
              }}
            >
              Next Verse
            </button>
          ))}
        {phase === ANSWERING &&
          (settings.touchscreen ? (
            <p className="important-information">
              Double-tap to show the verse.
            </p>
          ) : (
            <button
              className="clickable"
              onClick={() => {
                clickShowAnswer();
              }}
            >
              Show Answer
            </button>
          ))}
        {phase === RULING &&
          (settings.touchscreen ? (
            <div>
              <p className="important-information">
                Swipe right for 20 Points, left for Error.
              </p>
              <button
                className="link"
                onClick={() => makeRuling(2)}
                style={{ paddingLeft: 0 }}
              >
                Throwout
              </button>
            </div>
          ) : (
            <div>
              <Ruling onClick={(type) => makeRuling(type)} />
            </div>
          ))}
      </div>
      <Row>
        {(phase === "READY" || phase === "DONE") && (
          <div style={{ marginRight: 30 }}>
            <div>{`Total Verses: ${stats.current.totalVerses}`}</div>
            <div>{`Correct: ${stats.current.correct}`}</div>
            <div>{`Incorrect: ${stats.current.incorrect}`}</div>
            <div>
              {`Percentage Correct: ${
                stats.current.correct + stats.current.incorrect === 0
                  ? "0.00"
                  : Math.round(
                      (stats.current.correct * 1000) /
                        (stats.current.correct + stats.current.incorrect)
                    ) / 10.0
              }`}
            </div>
            <div>{`Verses Remaining: ${randomized.length}`}</div>
            <div>{`Total Time: ${getTimeDisplay(stats.current.time)}`}</div>
            <div>{`Time This Verse: ${getTimeDisplay(timeTaken.current)}`}</div>
            {(stats.current.correct > 0 || stats.current.incorrect > 0) && (
              <div>
                {`Average Time: ${getTimeDisplay(
                  stats.current.time /
                    (stats.current.correct + stats.current.incorrect)
                )}`}
              </div>
            )}
          </div>
        )}
        {phase === RULING && (
          <>
            <div
              style={{ marginRight: 30 }}
            >{`Time This Verse: ${getTimeDisplay(timeTaken.current)}`}</div>
            <div style={{ fontSize: 12, minHeight: 110 }}>
              {randomized[0]?.questions?.map((question, index) => (
                <div key={index}>{question}</div>
              ))}
            </div>
          </>
        )}
      </Row>
      <button
        className="back-button"
        style={{ marginTop: phase === "ANSWERING" ? 130 : 20 }}
        onClick={() => {
          if (timekeeperId.current) clearTimeout(timekeeperId.current);
          SpeechRecognition.stopListening();
          navigate("/");
        }}
      >
        Go Back
      </button>
    </DoubleClickable>
  );
}
